/* Quote Form */
.quoteIcon {
  display: flex;
  position: fixed;
  bottom: 10px;
  left: 15px;
  z-index: 3;
  cursor: pointer;
}

.quoteIconTop {
  z-index: 6;
}

.quoteIcon:before {
  position: absolute;
  content: attr(data-tooltip);
  opacity: 0;
  width: 100px;
  left: 100%;
  top: 30%;
}

.quoteIcon:hover:before {
  opacity: 1;
}
.chatIcon {
  display: flex;
  position: fixed;
  bottom: 10px;
  right: 15px;
  z-index: 3;
  cursor: pointer;
}

.chatIcon:before {
  position: absolute;
  content: attr(data-tooltip);
  opacity: 0;
  width: 200px;
  right: 25%;
  top: 30%;
}

.chatIcon:hover:before {
  opacity: 1;
}

.quoteModal {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #333;
  z-index: 5;
  justify-content: flex-end;
}

.quoteModal .quoteForm {
  display: none;
}

.openQuoteModal {
  animation-name: circularReveal;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
  height: 100%;
  width: 100%;
}

.closeQuoteModal {
  animation-name: circularHide;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
  transition: all 0.3s ease;
  height: 100%;
  width: 100%;
}

.openQuoteModal .quoteForm {
  display: flex;
  animation-name: appear;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
}

.quoteForm {
  opacity: 0;
}

.quoteRow {
  display: flex;
}

#dimension-unit {
  width: 100px;
  margin-left: 10px;
}

#package-type[disabled] {
  color: #707070;
}

select option:not(:first-of-type) {
  color: white;
}

.row {
  display: flex;
}

.left-column {
  width: 49%;
  margin-right: auto;
}

.right-column {
  width: 49%;
  margin-left: auto;
}

@keyframes circularReveal {
  0% {
    clip-path: circle(0% at 0% 100%);
  }
  100% {
    clip-path: circle(200% at 0% 100%);
  }
}

@keyframes circularHide {
  0% {
    clip-path: circle(200% at 0% 100%);
  }
  100% {
    clip-path: circle(0% at 0% 100%);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 600px) {
  .circularPattern {
    display: inline-block;
    height: 75%;
    width: 50%;
    background-color: #4b3ed2;
    margin-top: auto;
    margin-right: auto;
    clip-path: circle(0%);
  }

  .circularPatternReveal {
    animation-name: smallCircularReveal;
    animation-duration: 1000ms;
    animation-fill-mode: forwards;
  }

  .circularPatternHide {
    animation-name: smallCircularHide;
    animation-duration: 1000ms;
    animation-fill-mode: forwards;
  }

  @keyframes smallCircularReveal {
    0% {
      clip-path: circle(0% at 0% 100%);
    }
    100% {
      clip-path: circle(80% at 0% 100%);
    }
  }

  @keyframes smallCircularHide {
    0% {
      clip-path: circle(80% at 0% 100%);
    }
    100% {
      clip-path: circle(0% at 0% 100%);
    }
  }
}
